<template>
	<div id='main'>
		<!-- 顶部栏 -->
		<div id="main_top">
			<MainTop />
		</div>

		<div id="main_body">
			<!-- 中间内容区 -->
			<el-row id="main_sidebar_conts">
				<router-view />
			</el-row>
			<!-- 底部栏 -->
			<div id="main_bottom">
				<MainBottom />
			</div>
			<!-- 置顶按钮 -->
			<el-backtop target="#main_body" :right="20"></el-backtop>
		</div>

		<!-- 移动悬浮框 -->
		<!-- <div id="phone_float_box">
			<router-link to="/"><img src="https://dh123.hndh6666.com/images/icon-home.png" /></router-link>
			<a href="tel:400-048-1488"><img src="https://dh123.hndh6666.com/images/icon-tel.png" /></a>
			<a href="javascript:;" @click="showCodeImg=true"><img src="https://dh123.hndh6666.com/images/icon-wx.png" /></a>
		</div> -->
		<!-- <el-dialog :visible.sync="showCodeImg" width="200px" :center="true" :show-close="false" :title="msg"
			custom-class="dialog-codeimg">
			<img :src="codeImgSrc" />
		</el-dialog> -->
	</div>
</template>
<script>
	import MainTop from "./components/MainTop"
	import MainBottom from "./components/MainBottom"
	import {
		WOW
	} from 'wowjs'

	export default {
		components: {
			MainTop,
			MainBottom
		},
		data() {
			return {
				msg: "微信公众号",
				showCodeImg: false,
			}
		},
		methods: {
			// 顶部栏
			checkDivScroolTop() {
				//获取节点
				var scrollDiv = document.getElementById('main_body');
				//绑定事件
				scrollDiv.addEventListener('scroll', function() {
					var topValue = scrollDiv.scrollTop;
					if (topValue >= 100) {
						document.getElementById('main').classList.add('scroll');
					} else {
						document.getElementById('main').classList.remove('scroll');
					}
				});
			}
		},
		mounted() {
			// 顶部栏
			this.checkDivScroolTop();
			// wow初始化
			var wow = new WOW({
				scrollContainer: '#main_body',
				live: false,
				boxClass: 'wow',
				offset: 0,
				mobile: true
			})
			wow.init();
		}
	}
</script>

<style lang="scss" scoped>
	#main {
		height: 100%;
	}

	// 主结构
	#main_top {
		position: fixed;
		height: 74px;
		top: 0;
		left: 0;
		right: 0;
		z-index: 999;
		background-color: #fff;
		box-shadow: 0 1px 20px rgba(89, 95, 105, .3);
		padding: 7px 0;
		box-sizing: border-box;
		transition: all 0.5s ease;
	}

	#main_body {
		height: calc(100% - 74px);
		margin-top: 74px;
		overflow-y: scroll;
		transition: all 0.5s ease;
	}

	#main.scroll {
		#main_top {
			height: 60px;
			padding: 0;
		}

		#main_body {
			height: calc(100% - 60px);
			margin-top: 60px;
		}
	}

	#main_body::-webkit-scrollbar {
		width: 0;
		height: 0;
	}

	#main_bottom {
		width: 100%;
	}

	#phone_float_box {
		display: none;
	}

	@media screen and (max-width: 767px) {
		#main_top {
			height: auto !important;
			padding: 0;
		}

		#main_body {
			height: calc(100% - 50px) !important;
			margin-top: 50px !important;
		}

		#phone_float_box {
			display: block;
			width: 70%;
			margin: 0 15%;
			position: fixed;
			background: rgba(243, 243, 243, .9);
			box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
			bottom: 20px;
			height: 40px;
			border-radius: 80px;
			z-index: 10;

			a {
				width: 33%;
				height: 100%;
				float: left;
				line-height: 38px;
				text-align: center;

				img {
					vertical-align: middle;
					width: 18px;
				}
			}

			a:nth-child(2) {
				width: 34%;
				border-left: 1px solid rgba($color: #ffffff, $alpha: 0.5);
				border-right: 1px solid rgba($color: #ffffff, $alpha: 0.5);
				box-sizing: border-box;
			}
		}
	}
</style>
<style lang="scss">
	.el-menu-item.is-active {
		color: #EC9023 !important;
	}

	#main {
		.main-sidebar-nav .el-menu--horizontal>.el-menu-item.is-active {
			color: #EC9023 !important;
		}

		.el-menu--horizontal>.el-menu-item.is-active,
		.el-menu--horizontal>.el-submenu.is-active .el-submenu__title {
			border-bottom: 2px solid #EC9023;
		}

		.dialog-codeimg {
			box-shadow: none;
			background: transparent;

			.el-dialog__header span {
				color: #fff;
			}

			.el-dialog__body {
				padding: 0;

				img {
					width: 100%;
				}
			}
		}
	}
</style>
