import Vue from 'vue'
import VueRouter from 'vue-router'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import Main from '../views/Main.vue'

Vue.use(VueRouter, VueAwesomeSwiper)

const routes = [{
		path: '/',
		redirect: '/main'
	},
	{
		path: '/main',
		component: Main,
		children: [{
				path: '',
				name: 'index',
				component: () =>
					import('../views/pages/Index.vue')
			},
			{
				path: 'product',
				name: 'product',
				component: () =>
					import('../views/pages/product.vue')
			},
			{
				path: 'service',
				name: 'service',
				component: () =>
					import('../views/pages/Service.vue')
			},
			{
				path: 'aboutUS',
				name: 'aboutUS',
				component: () =>
					import('../views/pages/AboutUS.vue')
			},
			{
				path: 'aboutAPP',
				name: 'aboutAPP',
				component: () =>
					import('../views/pages/AboutAPP.vue')
			},
			{
				path: 'contactUS',
				name: 'contactUS',
				component: () =>
					import('../views/pages/ContactUS.vue')
			},
			{
				path: 'joinUs',
				name: 'joinUs',
				component: () =>
					import('../views/pages/JoinUs.vue')
			}
		]
	},
	{
		path: '*',
		name: '404',
		component: () =>
			import('../views/404.vue')
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		if (!document.getElementById('main_body')) {
			return false;
		}
		document.getElementById('main_body').scrollTop = 0;
	}
})

export default router

// 路由守卫
